<template>
    <div class="px-4">
        <form class="grid formgrid mb-5" @submit.prevent="doSearch()">
            <div class="col-10 mb-2 sm:col-4 lg:mb-0">
                <span class="p-input-icon-right w-full">
                    <InputText type="text" placeholder="Cari disini" class="w-full" v-model="keyword" />
                    <i class="pi pi-search" />
                </span>
            </div>
            <div class="col-2 mb-2 sm:col-2 lg:mb-0">
                <Button label="" type="submit" icon="pi pi-filter-fill" class="btn-white px-2">
                    <icon-enter></icon-enter>
                </Button>
            </div>
        </form>

        <table-list :data="data" :columns="columns"
            :loading="loading"
            :offset="offset"
            :limit="limit"
            :actions="['reset']"
            @rowClick="rowClick"
            @reset="resetConfirmation"
            @callbackPaginate="currentPage">
        </table-list>
    </div>

    <Dialog header="Confirmation" v-model:visible="reset_confirmation" :style="{width: '350px'}" :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
            <span>Are you sure you want to proceed?</span>
        </div>
        <template #footer>
            <Button label="No" icon="pi pi-times" @click="reset_confirmation = false" class="p-button-text" :disabled="reset_loading" />
            <Button class="p-button-text p-button-danger"
                @click="resetItem(storage)"
                :disabled="reset_loading">
                <span v-if="!reset_loading">Yes</span>
                <i class="pi pi-spin pi-spinner" v-else></i>
            </Button>
        </template>
    </Dialog>
</template>

<script>
import TableList from '@/components/Table'
import IconEnter from '@/components/IconEnter'

export default {
    components: {
        TableList, IconEnter,
    },
    data() {
        return {
            loading: false,
            limit: 10,
            offset: 0,
            keyword: '',
            data: [],
            all_data: [],
            reset_confirmation: false,
            reset_loading: false,
            storage: null,
            columns: [
                {
                    field: 'number_order',
                    header: 'No.',
                    process: (index) => this.offset + index + 1,
                },
                { field: 'name', header: 'Nama Lengkap', sortable: true, },
                { field: 'email', header: 'Email', sortable: true, },
                { field: 'phone', header: 'No. HP', sortable: true, },
                { 
                    field: 'level',
                    header: 'Member',
                    sortable: true,
                    process: (data) => this.membership[data],
                },
                {
                    field: 'flag_upgrade',
                    header: 'Upgrade Status',
                    sortable: true,
                    process: (data) => this.flag_upgraded[data],
                },
                { 
                    field: 'status',
                    header: 'Status',
                    sortable: true,
                    process: (data) => this.status[data],
                },
            ],
            membership: {
                '': { label: 'Regular', type: 'tag', class: 'mr-2 border-gray border-round-xl bg-white text-black opacity-60' },
                'REGULAR': { label: 'Regular', type: 'tag', class: 'mr-2 border-gray border-round-xl bg-white text-black opacity-60' },
                'PREMIUM': { label: 'Premium', type: 'tag', class: 'mr-2 bg-linear-secondary border-round-xl' },
            },
            status: {
                0: { label: 'Inactive', type: 'tag', class: 'danger', icon: 'pi pi-times' },
                1: { label: 'Active', type: 'tag', class: 'success', icon: 'pi pi-check' },
                2: { label: 'Blocked', type: 'tag', class: 'danger', icon: 'pi pi-times' },
            },
            flag_upgraded: {
                0: { label: 'Pending', type: 'tag', class: 'warning' },
                1: { label: 'Submited', type: 'tag', class: 'info' },
                2: { label: 'Upgraded', type: 'tag', class: 'success' },
                3: { label: 'Denied', type: 'tag', class: 'danger' },
            },
        }
    },
    mounted() {
        this.getData()
    },
    methods: {
        getData() {
            this.loading = true
            this.$http
                .get(`${process.env.VUE_APP_API_URL}/customers?status&flag_delete&flag_upgrade=1&global_filter=${this.keyword}`)
                .then(response => {
                    this.loading = false
                    this.data = response.data.data.customer
                    this.all_data = this.data
                })
        },
        doSearch() {
            let keyword = this.keyword.toLowerCase()
            
            if (keyword === 'inactive') {
                this.data = this.all_data.filter(item => item.status === 0)
                return
            }
            if (keyword === 'active') {
                this.data = this.all_data.filter(item => item.status === 1)
                return
            }
            if (keyword === 'blocked') {
                this.data = this.all_data.filter(item => item.status === 2)
                return
            }
            if (keyword === 'premium') {
                this.data = this.all_data.filter(item => item.level === 'PREMIUM')
                return
            }
            if (keyword === 'regular') {
                this.data = this.all_data.filter(item => item.level !== 'PREMIUM')
                return
            }
            if (keyword === 'pending') {
                this.data = this.all_data.filter(item => item.flag_upgrade === 0)
                return
            }
            if (keyword === 'submited') {
                this.data = this.all_data.filter(item => item.flag_upgrade === 1)
                return
            }
            if (keyword === 'upgraded') {
                this.data = this.all_data.filter(item => item.flag_upgrade === 2)
                return
            }
            if (keyword === 'denied') {
                this.data = this.all_data.filter(item => item.flag_upgrade === 3)
                return
            }
            
            this.data = this.all_data.filter(
                (item) =>
                    item.name.toLowerCase().includes(keyword) ||
                    item.email.toLowerCase().includes(keyword) ||
                    item.phone.toLowerCase().includes(keyword)
            );
        },
        rowClick(event) {
            this.$router.push({name: 'customer-list-upgrade-detail', params: {id: event.data.id}})
        },
        currentPage(event) {
            this.limit = event.rows
            this.offset = event.page * this.limit
        },
        resetConfirmation(data) {
            this.reset_confirmation = true
            this.storage = data
        },
        resetItem(data) {
            this.reset_loading = true

            this.$http.put(`${process.env.VUE_APP_API_URL}/customers/${data.id}/reset-kong`)
                .then(response => {
                    this.reset_loading = false

                    if (response.data.code !== 200) {
                        this.$toast.add({
                            severity:'error', 
                            summary: 'Error!', 
                            detail: response.data.message, 
                            life: 5000,
                        })
                        return
                    }
                    this.reset_confirmation = false
                    this.$toast.add({
                        severity:'success', 
                        summary: 'Success!', 
                        detail: response.data.message, 
                        life: 5000,
                    })
                }).catch(e => {
                    this.reset_loading = false
                    this.reset_confirmation = false
                    this.$toast.add({
                        severity:'error', 
                        summary: 'Error!', 
                        detail: e.response.data.message, 
                        life: 5000,
                    })
                })
        },
    },
}
</script>

<style lang="scss" scoped></style>
